export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const USIO_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const VALID_EMAIL = new RegExp(
	'^[a-zA-Z0-9._:$!%-+]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
);
export const VALID_PASSWORD = new RegExp(
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
);
export const VALID_PASSWORD_WITH_SYMBOL = new RegExp(
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
);

export const COMMON_FIELD_VALID = new RegExp(/^[A-Za-z\s-,&|]{3,100}$/);
export const PHONE_VALID = new RegExp(/^[+0-9]{10}$/);
export const LONG_FIELD_VALID = new RegExp(/^[A-Za-z0-9\s#-.(),/]{5,250}$/);
export const STATE_FIELD = new RegExp(/^[A-Za-z '.(),-‘]{2,50}$/);
export const POSTAL_CODE_VALID = new RegExp(/^[0-9]{5,50}$/);
export const CUSTOMER_NAME_VALID = new RegExp(/^[\w\s'-,-@&|\.]{3,50}$/);
export const CUSTOMER_PRIMARY_NAME_VALID = new RegExp(/^[\w\s',-\.]{3,50}$/);
export const URL_VALID = new RegExp(
	// eslint-disable-next-line max-len
	/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
);

export const INVOICE_TEMPLATES = {
	Classic: 'CLASSIC',
	Modern: 'MODERN',
	Minimalistic: 'MINIMALISTIC',
};

export const COMPANY_SIZES = [
	'SMALL',
	'MEDIUM',
	'LARGE'
]

export const DASHBOARD_PLUS_BUTTON_OPTIONS = [
	{ value: 'product', title: 'Product or Service' },
	{ value: 'customer', title: 'Customer' },
	{ value: 'estimate', title: 'Estimate' },
	{ value: 'invoice', title: 'Invoice' },
]
